import React, { useState } from "react";
import { Drawer, Box, Tabs, Tab, Switch, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { useGetAllCourses, useGetAllEventsOfCourse } from "../hooks/queries/CourseQuery";
import { v1 as uuid } from "uuid";
import { CourseGUI, TimetableEvent } from "../types/types";

type SidebarProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
  onAddEvents: (newEvents: TimetableEvent[]) => void;
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar, onAddEvents }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [samplePredmet, setSamplePredmet] = useState(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSamplePredmet(event.target.checked);
  };

  const allCourses = useGetAllCourses();
  const getAllEventsOfCourse = useGetAllEventsOfCourse();
  const [options, setOptions] = useState<CourseGUI[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<CourseGUI | null>({
    code: "",
    shortcut: "",
    name: "",
    type: "",
    facultyName: "",
    studyYear: 0,
    studyField: "",
  });

  const AddButtonClicked = () => {
    if (selectedCourse != null) {
      getAllEventsOfCourse.mutate(selectedCourse.code, {
        onSuccess: (data) => {
          onAddEvents(data);
        },
      });
    }
  };

  const handleInputChangeComboBox = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = e.target;
    if (value) {
      allCourses.mutate(value, {
        onSuccess: (data) => {
          const uniqueData = data.reduce((acc: typeof data, item) => {
            if (!acc.some((existingItem) => existingItem.name === item.name)) {
              acc.push({
                ...item,
                name: item.name.length > 50 ? item.name.slice(0, 50) + "..." : item.name,
              });
            }
            return acc;
          }, []);

          setOptions(uniqueData);
        },
      });
    } else {
      setOptions([]);
    }
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleSidebar}>
      <Box sx={{ width: 300, padding: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          variant="fullWidth"
          sx={{
            mb: 2,
            "& .MuiTab-root": {
              color: "#5ebed7",
              backgroundColor: "white",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "#5ebed7",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#5ebed7",
            },
          }}
        >
          <Tab label="Predmety" />
          <Tab label="Výmeny" />
        </Tabs>

        <Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Combobox
              value={selectedCourse}
              onChange={(value) => {
                setSelectedCourse(value);
              }}
            >
              <ComboboxInput
                displayValue={(course: CourseGUI) => course?.name}
                placeholder="Hľadať predmet"
                onChange={handleInputChangeComboBox}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              />
              <ComboboxOptions
                anchor="bottom"
                className="absolute bg-white border block rounded-lg border-gray-300 text-gray-900 text-sm"
                style={{ zIndex: 1300, width: 300 }}
              >
                {options.map((course) => (
                    <ComboboxOption key={uuid()} value={course}>
                        {`${course?.name} (${course?.facultyName})`}
                    </ComboboxOption>
                ))}
              </ComboboxOptions>
            </Combobox>

            <IconButton
              sx={{
                ml: 1,
                backgroundColor: "#5ebed7",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4aa8c3",
                },
              }}
              onClick={() => {
                AddButtonClicked();
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center">
            <Switch
              checked={samplePredmet}
              onChange={handleSwitchChange}
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#5ebed7",
                },
                "& .Mui-checked": {
                  color: "#5ebed7",
                },
              }}
            />
            <Typography variant="body1" ml={2}>
              Sample Predmet
            </Typography>
            <IconButton sx={{ ml: 1, color: "#5ebed7" }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
