import axios from "../../utils/axios";
import { CourseGUI, TimetableEvent } from "../../types/types";
import { useMutation } from "@tanstack/react-query";

export const useGetAllCourses = () => {
  return useMutation({
    mutationFn: async (substring: string): Promise<CourseGUI[]> => {
      const { data } = await axios.get(`Timetable/GetCoursesByNameSubstring/${substring}`);
      return data;
    },
  });
};

export const useGetAllEventsOfCourse = () => {
  return useMutation({
    mutationFn: async (code: String): Promise<TimetableEvent[]> => {
      const { data } = await axios.get(`Timetable/GetCourseTimetable/${code}`);
      return data;
    },
  });
};
