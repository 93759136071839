import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Timetable from "../components/Timetable";
import LoginModal from "../components/Modals/LoginModal";
import { useGetCurrentUsersTimetable, useGetUser } from "../hooks/queries/useLoginQuery";
import { TimetableEvent } from "../types/types";
import { useState } from "react";

const HomePage = () => {
  const { data: userData } = useGetUser();
  const { data: timetableData } = useGetCurrentUsersTimetable();

  // Local state to manage added events
  const [addedEvents, setAddedEvents] = useState<TimetableEvent[]>([]);

  const addTimetableEvents = (newEvents: any) => {
    if (Array.isArray(newEvents)) {
      // If newEvents is already an array
      setAddedEvents((prevEvents) => [...prevEvents, ...newEvents]);
    } else if (newEvents?.timetableEvents) {
      // If newEvents contains the array in 'timetableEvents'
      setAddedEvents((prevEvents) => [...prevEvents, ...newEvents.timetableEvents]);
    } else {
      console.error("Unexpected structure for newEvents:", newEvents);
    }
  };

  // Combine fetched data with added events
  const combinedEvents = timetableData ? [...timetableData, ...addedEvents] : addedEvents;

  return (
    <Box textAlign="center" display="flex" flexDirection="column" className="h-screen">
      <Navbar onAddEvents={addTimetableEvents} />
      <Timetable events={combinedEvents} />
      {userData?.personalNumber === "" && <LoginModal isOpen={true} />}
    </Box>
  );
};

export default HomePage;
