/* eslint-disable semi */
import { MappedTimeTableBlock, TimetableEvent } from "../types/types";
import Block from "./Block";
import React, { useMemo, useState } from "react";
import BlockForm from "./Modals/BlockForm";
import BlockModal from "./Modals/BlockModal";
import { mapFormDataToTimetableEvent, useAddEvent } from "../hooks/queries/EventQuery";
import { useGetUser } from "../hooks/queries/useLoginQuery";
import { v1 as uuid } from "uuid";
import ReactDOM from "react-dom";

type Attributes = {
  rows: string[];
  columns: string[];
  items: TimetableEvent[];
};

const Grid: React.FC<Attributes> = ({ rows, columns, items }) => {
  console.log("Grid props:", { rows, columns, items });
  const blocks = useMemo(() => {
    const itemsWithId = items.map((item) => ({ id: uuid(), ...item }));
    return itemsWithId.reduce((prev, curr) => {
      const allColision = itemsWithId.filter((item) => {
        if (item.day === curr.day) {
          const itemEndTime = item.startTime + item.duration;
          const currEndTime = curr.startTime + curr.duration;
          if (
            (itemEndTime > curr.startTime && itemEndTime < currEndTime) ||
            (currEndTime > item.startTime && currEndTime < itemEndTime) ||
            (curr.startTime === item.startTime && currEndTime === itemEndTime)
          ) {
            return true;
          }
        }
        return false;
      });

      const height = 1 / allColision.length;
      const order =
        [1, 2, 3, 4]
          .filter((position) => {
            const alreadySortedCollisions = prev.filter((prevItem) =>
              allColision.some((collisionItem) => collisionItem.id === prevItem.id)
            );
            return !alreadySortedCollisions.some((sortedColl) => sortedColl.order === position);
          })
          .at(0) ?? 1;

      const x = curr.startTime - 6;
      const y = curr.day;

      const blockInfo = {
        lessonShortcut: curr.course.shortcut,
        lectorName: curr.teachers.map((x) => x.fullName).join(", "),
        room: curr.room.name,
        lessonType: curr.type,
        duration: curr.duration,
        height,
      };

      const obj: MappedTimeTableBlock = {
        id: curr.id,
        width: curr.duration,
        x,
        y,
        order,
        height,
        blockInfo,
        timetableEvent: curr,
      };
      return [...prev, obj];
    }, [] as MappedTimeTableBlock[]);
  }, [items]);

  const initialFormData: TimetableEvent = {
    course: {
      code: "",
      shortcut: "",
      name: "",
      type: "",
    },
    room: {
      name: "",
      type: "",
    },
    teachers: [
      {
        fullName: "",
        roles: [{ roleType: "" }],
        email: "",
      },
    ],
    type: "",
    day: 1,
    startTime: 0,
    duration: 0,
    active: false,
  };

  const [formData, setFormData] = useState<TimetableEvent>(initialFormData);
  const [isAddFormOpen, setAddFormOpen] = useState(false);

  const openAddForm = () => {
    setAddFormOpen(true);
  };

  const closeAddForm = () => {
    setAddFormOpen(false);
  };

  const { data: userData } = useGetUser();
  const { mutate } = useAddEvent();

  const handleAddFormSubmit = async (formData: TimetableEvent) => {
    try {
      if (userData) {
        const timetableEvent = mapFormDataToTimetableEvent(formData);
        mutate({ timetableEvent: timetableEvent });
        console.log("Form submitted with data:", timetableEvent);
      } else {
        console.error("User data not available");
      }
    } catch (error) {
      console.log(error);
    }
    closeAddForm();
  };

  //BlockModal
  const [isModalOpen, setIsBlockModalOpen] = useState(false);

  const openBlockModal = () => {
    setIsBlockModalOpen(true);
  };

  const closeBlockModal = () => {
    setIsBlockModalOpen(false);
  };

  return (
    <div id="schedule" className="w-full h-full relative">
      <div
        className="grid grid-cols-15 w-full h-full"
        style={{
          gridTemplateColumns: `60px repeat(${columns.length - 1}, 1fr)`,
          gridTemplateRows: `60px repeat(${rows.length - 1}, 1fr)`,
        }}
      >
        {rows.map((row, rowIdx) => {
          return columns.map((col, colIdx) => {
            // .. Hours
            if (rowIdx === 0) {
              return (
                <div
                  key={`cell-${rowIdx}-${colIdx}`}
                  className="bg-white border flex border-[#ccc]"
                  style={{
                    height: "60px",
                  }}
                >
                  <p className="m-auto">{columns[colIdx]}</p>
                </div>
              );
            }

            // .. Days
            if (colIdx === 0) {
              return (
                <div
                  key={`cell-${rowIdx}-${colIdx}`}
                  className="bg-white flex border border-[#ccc]"
                  style={{
                    width: "60px",
                  }}
                >
                  <p className="m-auto">{rows[rowIdx]}</p>
                </div>
              );
            }

            return (
              <div
                key={`cell-${rowIdx}-${colIdx}`}
                id={`cell-${colIdx}-${rowIdx}`}
                className={["bg-transparent", "relative", "border", "border-[#ccc]"].join(" ")}
                style={{
                  minHeight: "100px",
                }}
                onClick={() => {
                  setFormData(initialFormData);
                  openAddForm();
                }}
              >
                <></>
              </div>
            );
          });
        })}
      </div>
      {blocks.map((block, index) => {
        const blockDiv = (
          <div
            key={`block-${index}`}
            className="absolute bg-blue-500 z-[1]"
            style={{
              top: `${block.height * 100 * block.order - block.height * 100}%`,
              left: 1,
              height: `${block.height * 100}%`,
              width: `${block.width * 100}%`,
            }}
            onClick={() => {
              setFormData(block.timetableEvent);
              openBlockModal();
            }}
          >
            <Block {...block.blockInfo} />
          </div>
        );

        const modalRoot = document.getElementById(`cell-${block.x}-${block.y}`);
        if (modalRoot) {
          return ReactDOM.createPortal(blockDiv, modalRoot);
        } else {
          return null;
        }
      })}
      <BlockForm isOpen={isAddFormOpen} onClose={closeAddForm} onSubmit={handleAddFormSubmit} initialData={formData} />
      <BlockModal isOpen={isModalOpen} onClose={closeBlockModal} initialData={formData} />
    </div>
  );
};

export default Grid;
